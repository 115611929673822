import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../Auth/authProvider";
import { ICrmlsUser } from "../../Auth/CrmlsUser";
import { RootState } from "../../app/store";
import { MongoQuery, SubjectRawRule, SubjectType } from "@casl/ability";
import { useAppSelector } from "../../app/hooks";
import { AdminRoles } from "../../Components/Utilities/Routes";

const pca = new PublicClientApplication(msalConfig);

export type tokenType = {
	mace: string | null;
	notification: string | null;
	membership: string | null;
	powerBi: string | null;
	training: string | null;
	ms: string | null;
	memberPortal: string | null;
	workflow: string | null;
};

export type crmlsUser = {
	user: ICrmlsUser | null;
	accountInfo: any | null;
	department: any | null;
	abilities: SubjectRawRule<string, SubjectType, MongoQuery<Record<string | number | symbol, unknown>>>[] | undefined;
	environment: "development" | "production" | "testing" | "staging";
	tokens: tokenType;
	accessRoles: string[];
};

// Define the initial state using that type
const initialState: crmlsUser = {
	user: null,
	accountInfo: null,
	department: null,
	abilities: [],
	environment: "development",
	accessRoles: [],
	tokens: {
		mace: "",
		notification: "",
		membership: "",
		powerBi: "",
		training: "",
		ms: "",
		memberPortal: "",
		workflow: "",
	},
};

export const fetchMaceToken = createAsyncThunk("user/fetchMaceToken", async (arg, thunkAPI) => {
	const request = {
		scopes: ["https://macewindu.azurewebsites.net/.default"],
	};

	return await pca
		.acquireTokenSilent(request)
		.then((response) => {
			thunkAPI.dispatch(getUserDepartment(response.accessToken));
			return response.accessToken;
		})
		.catch((error) => {
			// Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
			console.log(error);
			return null;
		});
});

export const fetchNotificationToken = createAsyncThunk("user/fetchNotificationToken", async () => {
	const request = {
		scopes: ["https://crmlsnotification.azurewebsites.net/.default"],
	};

	return await pca
		.acquireTokenSilent(request)
		.then((response) => {
			return response.accessToken;
		})
		.catch((error) => {
			// Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
			console.log(error);
			return null;
		});
});

export const fetchMembershipToken = createAsyncThunk("user/fetchMembershipToken", async () => {
	const request = {
		scopes: ["https://membershipdirectory.azurewebsites.net/.default"],
	};

	return await pca
		.acquireTokenSilent(request)
		.then((response) => {
			return response.accessToken;
		})
		.catch((error) => {
			// Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
			console.log(error);
			return null;
		});
});

export const fetchMemberPortalToken = createAsyncThunk("user/fetchMemberPortalToken", async () => {
	const request = {
		scopes: ["https://macewindu.azurewebsites.net/user_impersonation"],
	};

	return await pca
		.acquireTokenSilent(request)
		.then((response) => {
			return response.accessToken;
		})
		.catch((error) => {
			// Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
			console.log("Member portal api token error: ", error);
			return null;
		});
});

export const fetchWorkflowToken = createAsyncThunk("user/fetchWorkflowToken", async () => {
	const request = {
		scopes: ["api://workflow/.default"],
	};

	return await pca
		.acquireTokenSilent(request)
		.then((response) => {
			return response.accessToken;
		})
		.catch((error) => {
			console.log(error);
			// Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
			// console.log("Member portal api token error: ", error);
			return null;
		});
});

export const fetchPowerBiToken = createAsyncThunk("user/fetchPowerBiToken", async () => {
	const request = {
		scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
	};

	return await pca
		.acquireTokenSilent(request)
		.then((response) => {
			return response.accessToken;
		})
		.catch((error) => {
			// Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
			console.log(error);
			return null;
		});
});

export const fetchMsToken = createAsyncThunk("user/fetchMsToken", async (arg, thunkAPI) => {
	const request = {
		scopes: ["https://graph.microsoft.com/User.ReadBasic.All"],
	};

	return await pca
		.acquireTokenSilent(request)
		.then((response) => {
			// once we get the MS token grab the profile
			thunkAPI.dispatch(fetchProfileData(response.accessToken));
			return response.accessToken;
		})
		.catch((error) => {
			// Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
			console.log(error);
			return null;
		});
});

export const fetchProfileData = createAsyncThunk<ICrmlsUser, string, { fulfilledMeta: any }>("user/fetchProfileData", async (token: string, { fulfillWithValue, rejectWithValue }) => {
	const headers = { Authorization: `Bearer ${token}` };

	const svcEndpoint = "https://graph.microsoft.com/v1.0/me";
	const svcPhotoEndpoint = svcEndpoint + "/photo/$value";

	const authUser: ICrmlsUser = {
		id: "",
		login: "",
		isAuthenticated: false,
		first: "",
		last: "",
		title: "",
		department: "",
		userType: "",
		image: "",
		email: "",
	};

	try {
		const response = await fetch(svcEndpoint, { headers })
			.then((response) => response.json())
			.then((data) => {
				// console.log(data);
				if (data) {
					authUser.id = data["id"];
					authUser.email = data["mail"];
					authUser.title = data["jobTitle"];
					authUser.department = data["department"];
					authUser.userType = data["userType"];
					authUser.first = data["givenName"];
					authUser.last = data["surname"];
					authUser.isAuthenticated = true;
				}
				return authUser;
			})
			.then((authUser) => {
				return fetch(svcPhotoEndpoint, { headers })
					.then((response) => response.blob())
					.then((blob) => {
						const url = window.URL || window.webkitURL;
						authUser.image = url.createObjectURL(blob);
						return authUser;
					});
			});
		return fulfillWithValue(response, null);
	} catch (e) {
		return rejectWithValue((e as any).response);
	}
});

export const getUserDepartment = createAsyncThunk<ICrmlsUser, string, { fulfilledMeta: any }>("department/getUserDepartment", async (token: string, { fulfillWithValue, rejectWithValue }) => {
	const headers = { Authorization: `Bearer ${token}` };

	const authUser: ICrmlsUser = {
		id: "",
		login: "",
		isAuthenticated: false,
		first: "",
		last: "",
		title: "",
		department: "",
		userType: "",
		image: "",
		email: "",
	};

	try {
		const response = await fetch("https://macewindu.azurewebsites.net/api/User/current", { headers })
			.then((response) => response.json())
			.then((data) => {
				// console.log(data);
				if (data) {
					authUser.department = data["department"];
					authUser.userType = data["userType"];
				}
				return authUser;
			});
		return fulfillWithValue(response, null);
	} catch (e) {
		return rejectWithValue((e as any).response);
	}
});

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setAccountInfo: (state, action) => {
			state.accountInfo = action.payload;
		},
		setEnvironment: (state, action) => {
			state.environment = action.payload;
		},
		setAbilities: (state, action) => {
			state.abilities = action.payload;
		},
		setAccessRoles: (state, action) => {
			state.accessRoles = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchMaceToken.fulfilled, (state, action) => {
				state.tokens.mace = action.payload;
			})
			.addCase(fetchMembershipToken.fulfilled, (state, action) => {
				state.tokens.membership = action.payload;
			})
			.addCase(fetchMemberPortalToken.fulfilled, (state, action) => {
				state.tokens.memberPortal = action.payload;
			})
			.addCase(fetchNotificationToken.fulfilled, (state, action) => {
				state.tokens.notification = action.payload;
			})
			.addCase(fetchWorkflowToken.fulfilled, (state, action) => {
				state.tokens.workflow = action.payload;
			})
			.addCase(fetchPowerBiToken.fulfilled, (state, action) => {
				state.tokens.powerBi = action.payload;
			})
			.addCase(fetchMsToken.fulfilled, (state, action) => {
				state.tokens.ms = action.payload;
			})
			.addCase(fetchProfileData.fulfilled, (state, action) => {
				if (action.payload) {
					state.user = action.payload;
				}
			})
			.addCase(getUserDepartment.fulfilled, (state, action) => {
				if (action.payload) {
					state.department = action.payload;
				}
			});
	},
});
export const selectUserIdFromStore = createSelector(
	(state: RootState) => state.user.user,
	(user) => {
		if (user) {
			return user.id;
		}
	}
);

export const getUser = createSelector(
	(state: RootState) => state.user.user,
	(user) => {
		if (user) {
			return user;
		}
	}
);

export const getUserDept = createSelector(
	(state: RootState) => state.user.department,
	(department) => {
		if (department) {
			return department.department;
		}
	}
);

export const getUserType = createSelector(
	(state: RootState) => state.user.department,
	(department) => {
		if (department) {
			return department.userType;
		}
	}
);

export const getUserId = createSelector(
	(state: RootState) => state.user.accountInfo,
	(userInfo) => {
		if (userInfo) {
			return userInfo.localAccountId;
		}
	}
);

export const getAbilities = createSelector(
	(state: RootState) => state.user.abilities,
	(abilities) => {
		return abilities;
	}
);

export const getEnvironment = createSelector(
	(state: RootState) => state.user.environment,
	(environment) => {
		if (environment) {
			return environment;
		}
	}
);

export const getTokens = createSelector(
	(state: RootState) => state.user.tokens,
	(userTokens) => {
		if (userTokens) {
			return userTokens;
		}
	}
);

export const getAccessRoles = createSelector(
	(state: RootState) => state.user.accessRoles,
	(accessRoles) => {
		if (accessRoles) {
			return accessRoles;
		}
	}
);

export function useRoleCheck() {
	const accessRoles = useAppSelector(getAccessRoles);
	const isAdmin = accessRoles?.includes(AdminRoles.WorkFlowAdmin);
	return { isAdmin };
}

export const { setAccountInfo, setEnvironment, setAbilities, setAccessRoles } = userSlice.actions;
const { reducer } = userSlice;
export default reducer;
