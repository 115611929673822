import axios from "axios";
import MD5 from "crypto-js/md5";
import dayjs from "dayjs";
import { setReplyRead } from "../../features/complianceFeature/complianceSlice";
class QueryHelper {
	environment;
	// tokens
	campaignSupportToken;
	notificationToken;
	membershipToken;
	webApiToken;
	trainingToken;
	powerBiToken;
	memberPortalToken;
	workflowToken;
	// endpoints
	CampaignSupport;
	MemberDirectory;
	MembershipV3;
	Notification;
	Training;
	MemberPortal;
	Compliance;
	RegisteredListings;
	Workflow;
	CribData;
	// generics
	base_url;
	base_config;

	constructor({ environment, tokens }) {
		this.environment = environment;
		this.notificationToken = tokens.notification;
		this.campaignSupportToken = tokens.mace;
		this.workflowToken = tokens.workflow;
		this.membershipToken = tokens.membership;
		this.trainingToken = tokens.training;
		this.powerBiToken = tokens.powerBi;
		this.complianceToken = tokens.mace;
		this.memberPortalToken = tokens.memberPortal;
		// this.webApiToken = JSON.parse(context.environment.resoWebApiAccess).access_token;

		if (this.environment === "production" || this.environment === "staging") {
			// PROD and STAGING both hit PROD endpoints
			this.base_url = "https://macewindu.azurewebsites.net/api/app/";
			this.CampaignSupport = "https://macewindu.azurewebsites.net/api/app/";
			this.MemberDirectory = "https://membershipdirectory.azurewebsites.net/api/app/";
			this.MemberShipV3 = "https://membershipapi-prod.azurewebsites.net/api/app/";
			this.Notification = "https://crmlsnotification.azurewebsites.net/api/app/";
			this.Training = "https://trainingapi-prod.azurewebsites.net/api/app/";
			this.MemberPortal = "https://prod-memberportalapi.azurewebsites.net/api/app/";
			// Cosmos special case. Preview points to preview endpoints
			this.Compliance = `https://complianceapi-prod-app${this.environment === "staging" ? "-staging" : ""}.azurewebsites.net/api/app/`;
			this.RegisteredListings = "https://prod-registeredlistings-api.azurewebsites.net/";
			this.Workflow = "https://workflowapi-prod-app.azurewebsites.net/api/app/";
			this.CribData = "https://cribdata-prod.azurewebsites.net/api/app/";
		} else if (this.environment === "testing") {
			this.base_url = "https://macewindu-test.azurewebsites.net/api/app/";
			this.CampaignSupport = "https://macewindu-test.azurewebsites.net/api/app/";
			this.MemberDirectory = "https://membershipdirectory-api-test.azurewebsites.net/api/app/";
			this.MemberShipV3 = "https://membershipapi-test.azurewebsites.net/api/app/";
			this.Notification = "https://crmlsnotification-test.azurewebsites.net/api/app/";
			this.Training = "https://trainingapi-test.azurewebsites.net/api/app/";
			this.MemberPortal = "https://test-memberportalapi.azurewebsites.net/api/app/";
			this.Compliance = "https://complianceapi-uat-app-westus.azurewebsites.net/api/app/";
			this.RegisteredListings = "https://test-registeredlistings-api.azurewebsites.net/"; //TODO Maher confirm this
			this.Workflow = "https://workflowapi-test.azurewebsites.net/api/app/";
			this.CribData = "https://cribdata-prod.azurewebsites.net/api/app/";
		} else {
			// force to the dev endpoints
			this.base_url = "https://macewindu-dev.azurewebsites.net/api/app/";
			this.CampaignSupport = "https://macewindu-dev.azurewebsites.net/api/app/";
			this.MemberDirectory = "https://membershipdirectory-dev.azurewebsites.net/api/app/";
			this.MemberShipV3 = "https://membership-dev.azurewebsites.net/api/app/";
			this.Notification = "https://crmlsnotification-dev.azurewebsites.net/api/app/";
			this.Training = "https://trainingapi-dev.azurewebsites.net/api/app/";
			this.Compliance = "https://complianceapi-dev.azurewebsites.net/api/app/";

			this.MemberPortal = "https://dev-memberportalapi.azurewebsites.net/api/app/";
			this.RegisteredListings = "https://dev-registeredlistings-api.azurewebsites.net/"; //TODO Maher confirm this
			this.Workflow = "https://workflow-dev-app-westus.azurewebsites.net/api/app/";
			this.CribData = "https://cribdata-dev.azurewebsites.net/api/app/";
		}

		this.base_config = {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${this.campaignSupportToken}`,
			},
		};
	}

	operators = {
		Equal: 0,
		GreaterThan: 1,
		GreaterThanOrEqual: 2,
		LessThan: 3,
		LessThanOrEqual: 4,
		Between: 5,
		NotEqual: 6,
		NotEmpty: 7,
		Like: 8,
		HasAny: 9,
		Contains: 10,
		StartsWith: 11,
		NotLike: 12,
		ContainsAny: 13,
	};
	pageId = 0;
	pageSize = 50;
	search = [];
	field = "";
	criteria = [];
	orderBy = [];
	fields = [];
	use_q = "/q";

	get campaign_config() {
		return {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${this.campaignSupportToken}`,
			},
		};
	}

	get notification_config() {
		return {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${this.notificationToken}`,
			},
		};
	}

	get membership_config() {
		return {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${this.membershipToken}`,
			},
		};
	}

	get membership_new_config() {
		return {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${this.membershipToken}`,
			},
		};
	}

	get training_config() {
		return {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${this.campaignSupportToken}`,
			},
		};
	}

	get powerbi_config() {
		return {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${this.powerBiToken}`,
			},
		};
	}

	get compliance_config() {
		return {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${this.complianceToken}`,
			},
		};
	}

	get memberPortal_config() {
		return {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${this.memberPortalToken}`,
			},
		};
	}

	get workflow_config() {
		return {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${this.workflowToken}`,
			},
		};
	}

	get payload() {
		return {
			pageId: this.pageId,
			pageSize: this.pageSize,
			criteria: this.criteria,
			orderBy: this.orderBy,
			fields: this.fields,
		};
	}

	setMace() {
		this.base_url = this.CampaignSupport;
		this.base_config = this.campaign_config;
	}

	setNotification() {
		this.base_url = this.Notification;
		this.base_config = this.notification_config;
	}

	setMembership() {
		this.base_url = this.MemberDirectory;
		this.base_config = this.membership_config;
	}

	setTraining() {
		this.base_url = this.Training;
		this.base_config = this.training_config;
	}

	setMembershipV3() {
		this.base_url = this.MemberShipV3;
		this.base_config = this.membership_config;
	}

	setPowerBi() {
		this.base_config = this.powerbi_config;
	}

	setCompliance() {
		this.base_url = this.Compliance;
		this.base_config = this.compliance_config;
	}

	setMemberPortal() {
		this.base_url = this.MemberPortal;
		this.base_config = this.memberPortal_config;
	}

	setPowerBi() {
		this.base_config = this.powerbi_config;
	}

	setRegisteredListings() {
		this.base_url = this.RegisteredListings;
		this.base_config = this.compliance_config;
	}

	setWorkflow() {
		this.base_url = this.Workflow;
		this.base_config = this.workflow_config;
	}

	set payload(payload) {}

	async get(path) {
		const resp = await axios.get(this.base_url + path, this.base_config);
		return resp.data;
	}

	async put(path, payload) {
		const resp = await axios.put(this.base_url + path, payload, this.base_config);
		return resp.data;
	}

	async patch(path, payload) {
		const resp = await axios.patch(this.base_url + path, payload, this.base_config);
		return resp.data;
	}

	async post(path, payload) {
		const resp = await axios.post(this.base_url + path, payload, this.base_config);
		return resp.data;
	}

	async fetchData(resource) {
		const resp = await axios.post(this.base_url + resource + this.use_q, this.payload, this.base_config);
		return resp.data;
	}

	async fetchDataWithCriteria(resource, criteria) {
		this.criteria = criteria;
		const resp = await axios.post(this.base_url + resource + this.use_q, this.payload, this.base_config);
		return resp.data;
	}

	async fetchNotes(resource, entityIdKey, entityId, noteContextValue) {
		this.criteria = [
			{
				field: entityIdKey,
				op: 0,
				values: [entityId],
			},
			{
				field: "noteContext",
				op: 0,
				values: [noteContextValue],
			},
		];
		const resp = await axios.post(this.base_url + resource + this.use_q, this.payload, this.base_config);

		return resp.data;
	}

	async fetchDataWithCriteriaOrderBy(resource, criteria, orderBy) {
		this.criteria = criteria;
		this.orderBy = orderBy;
		const resp = await axios.post(this.base_url + resource + this.use_q, this.payload, this.base_config);

		return resp.data;
	}

	async fetchUserRoles() {
		const resp = await axios.get(`${this.base_url}UserRole`, this.base_config);
		return resp.data;
	}

	async fetchAllData(resource) {
		this.pageSize = 10000;
		let totalPages = 1;
		let results = [];

		let resp = await axios.post(resource == "user" && this.environment === "testing" ? "https://macewindu.azurewebsites.net/api/app/user" + this.use_q : this.base_url + resource + this.use_q, this.payload, this.base_config);
		results = resp.data.results;

		totalPages = resp.data.totalResults / resp.data.pageSize;

		while (this.pageId + 1 < totalPages) {
			this.pageId++;
			resp = await axios.post(this.base_url + resource + this.use_q, this.payload, this.base_config);
			results = results.concat(resp.data.results);
		}

		return results;
	}

	async fetchResource(resource, id, expanded) {
		let exp = expanded ? "/expanded" : "";
		const resp = await axios.get(this.base_url + resource + "/" + id + exp, this.base_config);
		return resp.data;
	}

	async createResource(resource, payload) {
		const resp = await axios.post(this.base_url + resource, payload, this.base_config);
		return resp.data;
	}

	async updateResource(resource, id, payload) {
		const resp = await axios.put(this.base_url + resource + "/" + id, payload, this.base_config);
		return resp.data;
	}

	async deleteResource(resource, id) {
		const resp = await axios.delete(this.base_url + resource + "/" + id, this.base_config);
		return resp.data;
	}

	// async changeStatus(campaign_id, new_status) {
	//     const resp = await axios.post(this.CampaignSupport + 'campaign/status/' + new_status + '/' + campaign_id, '', this.base_config);
	//     return resp.data;
	// }

	async fetchReportData(criteria) {
		const key = MD5(JSON.stringify(criteria)).toString();
		let existing = false;
		try {
			existing = JSON.parse(sessionStorage.getItem(key));
		} catch (e) {
			console.log(e);
		}

		if (existing && existing.hasOwnProperty("timestamp")) {
			if (dayjs.unix(existing.timestamp) > dayjs().add(50, "second").unix()) {
				try {
					console.log("returning cached");
					return new Promise(function (resolve, reject) {
						setTimeout(() => resolve(existing.data), 1);
					});
				} catch (e) {
					console.log(e);
				}
			} else {
				console.log("Cache expired");
			}
		} else {
			console.log("No cache, returning fresh");
			const resp = await axios.post(this.Notification + "WebhookEvent/Stats", criteria, this.notification_config);
			let cached_data = JSON.stringify({
				timestamp: dayjs().unix(),
				data: resp.data,
			});

			sessionStorage.setItem(key, cached_data);
			return resp.data;
		}
	}
	async fetchMembershipAudienceMembers() {
		const resp = await axios.get(`${this.MemberShipV3}AudienceIndexes/ta/${this.criteria[0].values[0]}?top=50`, this.membership_config);

		return resp.data;
	}
	async fetchMembershipAudienceMember(id) {
		let payload = {
			pageId: 0,
			pageSize: 1,
			criteria: [
				{
					field: "contactId",
					op: 0,
					values: [id],
				},
			],
		};
		const resp = await axios.post(`${this.MemberShipV3}AudienceIndexes/q`, payload, this.membership_config);

		return resp.data;
	}
	async fetchMembershipAudienceMemberDenormalized(id) {
		let payload = {
			pageId: 0,
			pageSize: 1,
			criteria: [
				{
					field: "contactId",
					op: 0,
					values: [id],
				},
			],
		};
		const resp = await axios.get(`${this.MemberShipV3}Contacts/${id}/denormalized`, this.membership_config);

		return resp.data;
	}

	async fetchAudienceMembers() {
		const resp = await axios.post(
			this.MemberDirectory + "AudienceIndex/q",
			{
				...this.payload,
				orderBy: [
					{
						field: "lastName",
						direction: "Asc",
					},
				],
				pageSize: 200,
			},
			this.membership_config
		);
		return resp.data;
	}

	//https://dev-memberportalapi.azurewebsites.net/api/app/AudienceIndexes/ta/Dan
	async fetchAudienceMembersTa(searchTerm) {
		const resp = await axios.get(this.MemberPortal + `AudienceIndexes/ta/${searchTerm}`, this.base_config);
		return resp.data;
	}
	async fetchAudienceMemberTa(contact_id) {
		const resp = await axios.get(this.MemberPortal + `AudienceIndexes/${contact_id}`, this.base_config);
		return resp.data;
	}

	async fetchAudienceTypeAhead(searchString) {
		const resp = await axios.get(this.MemberDirectory + "AudienceIndexes/ta/" + searchString, this.membership_config);
		return resp.data;
	}

	async fetchAudienceMember(contact_id) {
		let payload = {
			pageId: 0,
			pageSize: 1,
			criteria: [
				{
					field: "contactId",
					op: 0,
					values: [contact_id],
				},
			],
		};
		const resp = await axios.post(this.MemberDirectory + "AudienceIndex/q", payload, this.membership_config);
		return resp.data;
	}

	async fetchAllAudienceMembers() {
		// set limit to max
		this.pageSize = 10000;
		let totalPages = 1;
		let results = [];
		let resp = await axios.post(this.MemberDirectory + "AudienceIndex/q", this.payload, this.membership_config);
		results = resp.data.results;

		totalPages = resp.data.totalResults / resp.data.pageSize;

		while (this.pageId + 1 < totalPages) {
			this.pageId++;
			resp = await axios.post(this.MemberDirectory + "AudienceIndex/q", this.payload, this.membership_config);
			results = results.concat(resp.data.results);
		}

		return results;
	}

	async sendTestEmail(criteria) {
		const resp = await axios.post(this.CampaignSupport + "Notification/SendPreview", criteria, this.campaign_config);
		return resp.data;
	}

	async unsubscribe(criteria) {
		const resp = await axios.post(this.Notification + "OptOutRequest/Unsubscribe", criteria, this.base_config);
		return resp.data;
	}

	async csvUpload(audience_id, file) {
		const resp = await axios.post(this.MemberDirectory + "CustomContact/uploadCsvFile/" + audience_id, file, {
			headers: {
				"Content-Type": "multipart/form-data",
				Accept: "application/json",
				Authorization: `Bearer ${this.membershipToken}`,
			},
		});
		return resp.data;
	}

	async fetchMemberCount(start_date, end_date) {
		this.criteria = [
			{
				field: "CreatedOn",
				op: 1,
				values: [start_date],
			},
			{
				field: "CreatedOn",
				op: 3,
				values: [end_date],
			},
		];
		this.orderBy = [
			{
				field: "CreatedOn",
				direction: "Desc",
			},
		];
		this.pageSize = 10000;

		const resp = await axios.post(this.MemberDirectory + "MemberCount/q", this.payload, this.membership_config);
		return resp.data;
	}

	async fetchMemberRollup(start_date, end_date) {
		this.criteria = [
			{
				field: "CreatedOn",
				op: 1,
				values: [start_date],
			},
			{
				field: "CreatedOn",
				op: 3,
				values: [end_date],
			},
			{
				field: "lastDayOfMonth",
				op: 0,
				values: ["true"],
			},
		];
		this.orderBy = [
			{
				field: "CreatedOn",
				direction: "Desc",
			},
		];
		this.pageSize = 10000;

		const resp = await axios.post(this.MemberDirectory + "MemberCount/q", this.payload, this.membership_config);
		return resp.data;
	}

	async fetchListing(listing_id) {
		// eq 'SW18192065'
		if (listing_id && listing_id.length > 4) {
			const resp = await axios.get("https://staging.h.api.crmls.org/RESO/OData/Property?$filter=ListingId eq '" + listing_id + "'&$expand=Media", {
				headers: {
					Accept: "application/json",
					Authorization: `Bearer ${this.webApiToken}`,
				},
			});
			return resp.data;
		}
	}

	async fetchReportEmbedUrl(report_id) {
		const resp = await axios.get("https://api.powerbi.com/v1.0/myorg/groups/f26d3ff1-a9f5-4bee-9cae-eaef04d77f74/reports/" + report_id, {
			headers: {
				Accept: "application/json",
				Authorization: `Bearer ${this.powerBiToken}`,
			},
		});

		return {
			token: this.powerBiToken,
			embedUrl: resp.data.embedUrl,
		};
	}

	async fetchAttendees(class_id = false) {
		if (class_id) {
			this.criteria = [
				{
					field: "trainingClassId",
					op: 0,
					values: [class_id],
				},
			];

			this.orderBy = [
				{
					field: "createdOn",
					direction: 0,
				},
			];
		}

		const resp = await axios.post(this.base_url + "attendee/q", this.payload, this.training_config);
		return resp.data;
	}

	async fetchTopicsByClass(id) {
		return await axios.post(
			`${this.base_url}trainingClass/q?stupid`,
			{
				pageId: 0,
				pageSize: 20,
				criteria: [
					{
						field: "id",
						op: 0,
						values: [id],
					},
				],
			},
			this.base_config
		);
	}

	async getRenderedPdf(campaignId) {
		return await axios.get(`${this.base_url}Campaign/PDFRender/${campaignId}`, this.base_config);
	}

	async getRegisteredListings() {
		return await axios.post(this.RegisteredListings + "api/app/Registration/q", {
			pageId: 0,
			pageSize: 10000,
		});
	}
	async getMemberManagers(memberId) {
		this.setCompliance();
		return this.get(`ComplianceCases/memberManagers/${memberId}`);
		//return this.get(`ComplianceCases/memberManagers/2744FB2C-528C-41F0-989C-0000D81B8C67`);
	}
	async getCaseAlerts(caseId) {
		this.setCompliance();
		return this.get(`Alerts/complianceCase/${caseId}`);
	}
	async dismissAlert(alertId) {
		this.setCompliance();
		return this.put(`Alerts/dismissAlert/${alertId}`);
	}
	async dismissAllAlerts(caseId) {
		this.setCompliance();
		return this.put(`Alerts/dismissAll/${caseId}`);
	}
	async getDispositions(type) {
		this.setCompliance();

		this.pageSize = 100000;
		return this.fetchDataWithCriteria("EnumerationLookups", [
			{
				field: "enumerationName",
				op: 0,
				values: [type], //Todo "Complaints"
			},
		]);
	}
	async replyRead(reply, notice, dispatch) {
		this.setCompliance();
		dispatch(setReplyRead({ noticeId: notice.id, replyId: reply.id }));
		return this.put(`Replies/${reply.id}`, {
			...reply,
			isRead: true,
		});
	}

	async uploadReportCsv(formData, userId, notifyUser) {
		return await axios.post(this.Compliance + `ICheck/upload?notifyUser=${notifyUser}&userId=${userId}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${this.complianceToken}`,
				Accept: "application/json",
			},
		});
	}

	async getCribData(listingId, cancelToken, includeLong = true) {
		return await axios.get(this.CribData + `${includeLong && "long/"}${listingId}?include=media`, cancelToken);
	}
}

export default QueryHelper;
