import React, { useEffect } from "react";
import Breadcrumbs from "./Components/Widgets/Breadcrumbs";
import { useAppDispatch } from "./app/hooks";
import { fetchMaceToken, fetchMembershipToken, fetchMemberPortalToken, fetchNotificationToken, fetchPowerBiToken, fetchMsToken, setAccountInfo, fetchWorkflowToken, setEnvironment } from "./features/userFeature/userSlice";
import Footer from "./Components/Footer";
import LogoBar from "./Components/LogoBar";
import Main from "./Components/Main";
import Header from "./Components/Header";
import { useMsal } from "@azure/msal-react";
import { AbilityContext } from "./Auth/can";
import { defineAbility } from "@casl/ability";
import { getCaslAbilities } from "./Auth/caslAuth";
import { useAppSelector } from "./app/hooks";
import { getUserDept } from "./features/userFeature/userSlice";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import axios from "axios";
import { DraggableModalProvider } from "ant-design-draggable-modal";

function App() {
	const dispatch = useAppDispatch();
	const { accounts } = useMsal();
	const department = useAppSelector(getUserDept);
	// set default abilities
	const caslAbilities = defineAbility((can, cannot) => {
		cannot("manage", "all"); // not able to do anything but read
		can("read", "all"); // and can read all
	});

	useEffect(() => {
		dispatch(setAccountInfo(accounts[0]));
		// if we have an account, get the abilities for that account
		getCaslAbilities(caslAbilities, department);
		// set the environment
		if (window.location.href.includes("localhost") || process.env.NODE_ENV === "development" || window.location.href.includes("ui-dev") || window.location.href.includes("cosmos-dev")) {
			dispatch(setEnvironment("development"));
		} else if (window.location.href.includes("test-maceui") || window.location.href.includes("cosmos-uat") || window.location.href.includes("maceui-wfuat")) {
			dispatch(setEnvironment("testing"));
		} else if (window.location.href.includes("maceui-staging") || window.location.href.includes("cosmos-preview")) {
			dispatch(setEnvironment("staging"));
		} else if (window.location.href.includes("cosmos.crmls")) {
			dispatch(setEnvironment("production"));
		} else {
			dispatch(setEnvironment("development"));
		}
	}, [caslAbilities, dispatch, accounts]);

	const fetchTokens = () => {
		if (dispatch) {
			return dispatch(fetchMaceToken())
				.then(() => dispatch(fetchMembershipToken()))
				.then(() => dispatch(fetchMemberPortalToken()))
				.then(() => dispatch(fetchNotificationToken()))
				.then(() => dispatch(fetchPowerBiToken()))
				.then(() => dispatch(fetchWorkflowToken()))
				.then(() => dispatch(fetchMsToken()).then(() => Promise.resolve()));
		} else {
			return Promise.reject("No dispatch");
		}
	};

	useEffect(() => {
		fetchTokens();

		// Set interval to refresh tokens every 30 minutes - to prevent random 401 error on axios requests after sitting idle
		const tokenInterval = setInterval(() => {
			console.log("Fetching tokens on 30min timer");
			fetchTokens();
		}, 1800000);

		return () => {
			clearInterval(tokenInterval);
		};
	}, [dispatch]);

	useEffect(() => {
		let lastRefresh = Date.now();

		const handleFocus = () => {
			const now = Date.now();
			const tenMinutes = 10 * 60 * 1000;

			if (now - lastRefresh > tenMinutes) {
				fetchTokens().then(() => {
					lastRefresh = Date.now();
					console.log("fetching tokens", new Date(lastRefresh).toLocaleTimeString());
				});
			}
		};

		window.addEventListener("focus", handleFocus);

		return () => {
			window.removeEventListener("focus", handleFocus);
		};
	}, [dispatch]);

	const version = "3.6.2";

	// refresh token mechanism
	createAuthRefreshInterceptor(axios, fetchTokens);

	return (
		<DraggableModalProvider>
			<AbilityContext.Provider value={caslAbilities}>
				<LogoBar version={version} />
				<main className="content">
					<Header />
					<div className="page-subheader mb-30">
						<Breadcrumbs />
					</div>
					<div id="reactMainContainer" className="page-content d-flex flex">
						<Main />
					</div>
					<Footer />
				</main>
			</AbilityContext.Provider>
		</DraggableModalProvider>
	);
}

export default App;
